import { Col, Row, Form, Card, Button, Alert, Spinner, Accordion } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import React,{ useState, useEffect } from "react";
import { faFileCsv, faTimes, faUpload, faArrowLeft, faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import Logout from "../../framework/utilities/Logout";

const ConflictExport = () => {

    const heading="Conflict Export";

    const API_URL = process.env.REACT_APP_API_URL;

    const [reviewerFiles, setFilesReviewer] = useState([]);
    const [authorFiles, setFilesAuthor] = useState([]);

    const [fileUploadProgress, setFileUploadProgress] = useState(false);
    const [errMessage,setErrorMessage] = useState('');
    const [successMessage,setSuccessMessage] = useState('');
    const [invalidUserList, setInvalidUserList] = useState([]);
    const [fileRejectionItems,setFileRejectionItems] = useState('');
    const [showImportNowBtn,setShowImportNowBtn] = useState(false);
    const [fileToken,setFileToken] = useState('');

    const [downloadLink, setDownloadLink] = useState('');

    const [stepStatus, setStepStatus] = useState(1);
    const [uploadButtonText, setUploadButtonText] = useState('Upload');

    const [uploadStatus, setUploadStatus] = useState(0);



    const conferenceId = 'DEFAULT';
    const conferenceName = 'Default';
    const [isLoadingConferences, setIsLoadingConferences] = useState(true);
    
    // default rules for all types stored in relations table
    const [defaultRules,setDefaultRules] = useState([]);
    const [selectedRules, setSelectedRules] = useState([]);

    // pending employment rules
   // const [defaultRulesPendingEmp,setDefaultRulesPendingEmp] = useState([]);
    const [selectedRulesPendingEmp, setSelectedRulesPendingEmp] = useState([]);

    // for institutions
    const [defaultRulesInst, setDefaultRulesInst] = useState([]);
    const [selectedRulesInst, setSelectedRulesInst] = useState([]);

    const [communityPapers, setCommunityPapers] = useState([]);
    const [communityPapersCheck, setCommunityPapersCheck] = useState(0);
    
    const [selectedRuleCommunity, setSelectedRuleCommunity] = useState([]);
    
    const { getRootProps:getRootReviewerProps , getInputProps:getInputReviewerProps } = useDropzone({
        accept: '.csv',  
        maxFiles: 1,
        maxSize: 10000000,//10MB
        multiple: false,        
        onDrop: (acceptedFiles,fileRejections) => {
            setFilesReviewer(
                acceptedFiles.map(file => Object.assign(
                    file, { preview: URL.createObjectURL(file), base64Data: file.base64Data }
                ))
            );

            const fileRejectionItems = getfileRejectionsError(fileRejections, 'Reviewer');
            
            if (fileRejectionItems.length > 0)
                setFileRejectionItems(fileRejectionItems);
            else
                setFileRejectionItems('');
        },
        getFilesFromEvent: event => myCustomFileGetter(event)
    });

    const thumbs = reviewerFiles.map(file => (
        <div style={{display: 'inline-flex',borderRadius: 2,border: '1px solid #eaeaea',marginBottom: 8,marginRight: 8,width: "100%",height: 100,padding: 4,boxSizing: 'border-box'}} key={file.name}>
            <div style={{display: 'flex',minWidth: 0,overflow: 'hidden', height: '60px'}}>
                {
                    (file.type.includes('image')) ? 
                    <img
                        src={file.preview}
                        style={{display: 'block',width: 'auto',height: '100%'}}
                        alt=""
                    />
                    :
                    <>
                        <FontAwesomeIcon icon={faFileCsv} style={{width:"auto",height:"100%"}} />
                        <strong style={{ paddingLeft: "10px", display: "inline-flex" , justifyContent: "center" }}>
                            <span style={{ alignItems: "center" ,display: "inline-flex"  }}>
                                {file.name}
                            </span>
                        </strong>        
                    </>
                }
                
            </div>
        </div>
    ));

    //console.log( getRootProps);

    const { getRootProps:getRootAuthorProps ,getInputProps:getInputAuthorProps } = useDropzone({
        accept: '.csv',  
        maxFiles: 1,
        maxSize: 10000000,//10MB
        multiple: false,        
        onDrop: (acceptedFiles,fileRejections) => {
            setFilesAuthor(
                acceptedFiles.map(file => Object.assign(
                    file, { preview: URL.createObjectURL(file), base64Data: file.base64Data }
                ))
            );

                
            const fileRejectionItems = getfileRejectionsError(fileRejections, 'Author');
                
               
            if (fileRejectionItems.length > 0)
                setFileRejectionItems(fileRejectionItems);
            else
                setFileRejectionItems('');
        },
        getFilesFromEvent: event => myCustomFileGetter(event)
    });

   

    const thumbsAuth = authorFiles.map(file => (
        <div style={{display: 'inline-flex',borderRadius: 2,border: '1px solid #eaeaea',marginBottom: 8,marginRight: 8,width: "100%",height: 100,padding: 4,boxSizing: 'border-box'}} key={file.name}>
            <div style={{display: 'flex',minWidth: 0,overflow: 'hidden', height: '60px'}}>
                {
                    (file.type.includes('image')) ? 
                    <img
                        src={file.preview}
                        style={{display: 'block',width: 'auto',height: '100%'}}
                        alt=""
                    />
                    :
                    <>
                        <FontAwesomeIcon icon={faFileCsv} style={{width:"auto",height:"100%"}} />
                        <strong style={{ paddingLeft: "10px", display: "inline-flex" , justifyContent: "center" }}>
                            <span style={{ alignItems: "center" ,display: "inline-flex"  }}>
                                {file.name}
                            </span>
                        </strong>        
                    </>
                }
                
            </div>
        </div>
    ));


    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        reviewerFiles.forEach(file => URL.revokeObjectURL(file.preview));
        authorFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }, [reviewerFiles, authorFiles]);

    useEffect(()=>{
        const tmp = [];
        const tmprules = [];
        tmp.push({
            value: conferenceId,
            label: conferenceName
        });
        const API_URL = process.env.REACT_APP_API_URL;
        fetch(API_URL + '/v1/web/conference/data/list/conferDrpList', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            }
        }).then(response => response.json())
        .then(jsondata => {
           // console.log(jsondata);
            if (jsondata === 'Unauthorized'){
                Logout();
            }
           
            // for creating the conference dropdown
            Object.keys(jsondata.options).forEach(key => {
                tmp.push({
                    value: key,
                    label: jsondata.options[key]
                });
            });                
           // setConferenceOptions(tmp);
            
            // creating array for rules. This is used to display all the checkboxes
            Object.keys(jsondata.default_rules).forEach(key => {
                tmprules.push({
                    value: key,
                    label: jsondata.default_rules[key].rule_title,
                    relation_type_id: jsondata.default_rules[key].relation_type_id,
                    start_date_check: jsondata.default_rules[key].start_date_check,
                    end_date_check: jsondata.default_rules[key].end_date_check,
                    readonly_field: jsondata.default_rules[key].readonly_field,
                    operation_txt: jsondata.default_rules[key].operation_txt,
                    number_of_year: jsondata.default_rules[key].number_of_year
                });

               
            });   
           
            setDefaultRules(tmprules);
            setIsLoadingConferences(false);
           
        }).catch((err) => {
            //Logout();
            console.log("Login error: " + err);
            setIsLoadingConferences(false);
        });

        // getting institution conflcits
        fetch(API_URL + '/v1/web/institution/data/InstitutionConflictOption' , {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            }        
        })
        .then(response => response.json())
        .then(jsondata => {      
            if(jsondata.return_data)
                setDefaultRulesInst(jsondata.return_data);
        }).catch((err) => {
            console.log("Login error: " + err);
           
        });


        // getting community papers
        fetch(API_URL + '/v1/relation/data/getCommunityPapers' , {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            }        
        })
        .then(response => response.json())
        .then(jsondata => {      
            if(jsondata.return_data)
                //console.log(jsondata.return_data);
                setCommunityPapers(jsondata.return_data);
                setCommunityPapersCheck(1);
    
        }).catch((err) => {
            console.log("Login error: " + err);
           
        });


    },[conferenceId,conferenceName,selectedRules]);

    const nextStep = () =>{
        let step = stepStatus + 1; 
        setStepStatus(step);
    }

    const previousStep = () =>{
        let step =stepStatus - 1;
        setStepStatus(step);
        setSuccessMessage("");
        setErrorMessage("");
        setDownloadLink("");
    }

    const handleImportNow = () =>{

        //console.log(fileToken);
        
        //console.log("comes here handleImportNow" ,fileToken);
        if (fileToken !== ''){

            var formData = new FormData();
            formData.append("token", fileToken);
            formData.append("selectedRulesPendingEmp", JSON.stringify(selectedRulesPendingEmp));
            formData.append("selectedRules", JSON.stringify(selectedRules));
            formData.append("selectedRulesInst", JSON.stringify(selectedRulesInst));

            formData.append("selectedRuleCommunity", JSON.stringify(selectedRuleCommunity));

           //console.log(selectedRules);

           setDownloadLink("");

            fetch(API_URL + '/v1/relation/data/generate_export', { 
                method: 'POST', 
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token"),                    
                }, 
                body: formData 
            })
            .then(response => response.json())
            .then(jsondata => {              
                if (jsondata.error === 'SET_FOR_IMPORT_FAILED'){
                    setErrorMessage(jsondata.message);   
                }else if (jsondata.message === 'EXPORT_SUCCESS'){    
                    
                    setInvalidUserList(jsondata.invalid_array);

                    //console.log(selectedRules);

                    //console.log(csv_link);

                    if(jsondata.csv_link)
                        setDownloadLink(jsondata.csv_link);
                        nextStep();
                        

                    //setExportData(jsondata.export_table);

                    // setSuccessMessage(() => {
                    //     return (<><p>The file is set for import. You may close this module and logout from admin.</p>
                    //         <p>If the records are not imported in next one hour it could be due to cron failure. In that case please contact us.</p></>
                    //     )
                    // });
                    //setShowImportNowBtn(false);
                }               
            }).catch((err) => {                
                console.log("Login error: " + err);
                setSuccessMessage("File could not be set for import.")
            });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log("*************");
        // console.log(reviewerFiles);
        // console.log(authorFiles);

        //console.log(selectedRulesPendingEmp);
        //console.log(selectedRules);
        //return false;
        
        if(uploadStatus === 1){
            setUploadStatus(0);
            setUploadButtonText('Upload');
        }
        else if (reviewerFiles.length === 0 && authorFiles.length === 0){
            setErrorMessage("Please upload the CSV file.");   
            setSuccessMessage("");         
        }else{
            setErrorMessage(""); 
            setSuccessMessage("");           
            setFileUploadProgress(true);

            if (reviewerFiles.length > 0 && authorFiles.length > 0){
                var formData = new FormData();
                //formData.append("FileData", files.base64Data);
                formData.append("FileData", reviewerFiles[0]);            
                formData.append("FileData1", authorFiles[0]);            
                //formData.append("FileBase64", files[0].base64Data);
    
                fetch(API_URL + '/v1/relation/data/upload_reviewer_csv', { 
                    method: 'POST', 
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem("token"),                    
                    }, 
                    body: formData 
                })
                .then(response => response.json())
                .then(jsondata => {
                    setFilesReviewer([]);
                    setFilesAuthor([]);

                    if (jsondata.message === 'UPLOAD_FAILED'){
                        setErrorMessage(jsondata.errMessage);
                    }else{
                        //console.log("successful");
                        //setSuccessMessage("File uploaded successfully. Please click on \"Generate conflict\" button to generate the output file."); 
                        setUploadStatus(1); 
                        let step = stepStatus + 1;
                        setStepStatus(step);
                        setUploadButtonText('Upload new file');
                        //hide uploader blocks
                        
                        setFileToken(jsondata.file_path_token);

                        //console.log(jsondata.file_path_token);

                        setShowImportNowBtn(true);
                    }
                    setFileUploadProgress(false);
                }).catch((err) => {
                    setFilesReviewer([]);
                    setFilesAuthor([]);
                    console.log("Login error: " + err);
                    setFileUploadProgress(false);
                    setErrorMessage("File could not be saved. Please try after some time.");
                });
            }   
        }
            

    }

    return ( 
        <>
        
            <ModuleBreadcrumb links={[{ label: heading }]}  />
            {fileUploadProgress && !errMessage && <Alert variant="info">File upload in progress...</Alert>}
            {errMessage && <Alert variant="danger">{errMessage}</Alert>}
            {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}  

            {fileRejectionItems && 
                <div>
                    <strong className="text-danger">The following files could not be uploaded.</strong>
                    <ul className="text-danger">{fileRejectionItems}</ul>  
                </div>
            }

            { (stepStatus === 1) &&
                <Row>
                    <Col className="mt-2" xs={12}>
                        <h4>Step 1: Check for possible community papers</h4>
                        <br/>
                        
                        {  (communityPapersCheck === 0) ?
                            <strong>Please wait, fetching for community paper list.</strong>
                        :
                            <>
                            { (communityPapers.length > 0) &&
                                <Col className="mt-1 callout callout-info">
                                    <b>NOTE:</b>

                                    <p>Below is a list of all the community papers added by the users. You need to make a per-paper decision as to whether or not the paper is, indeed, a community paper or no. Click on the the checkbox if it is not a community paper and then this paper will not constitute a conflict and will be ignored in conflict checking. Any selection you make here will not overwrite the submission done by users.</p>
                                </Col>
                            }

                            <div className="mt-1">
                            { 
                                ( communityPapers.length > 0 ) ? 
                                    communityPapers.map((row, index) => ( 
                                        
                                        <Form.Group key={`key-${index}-${row.id}`} className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check 
                                                type="checkbox"
                                                label={`${row.publication_name}`} 
                                                checked={(selectedRuleCommunity.filter(i =>
                                                    [row.id].includes(i.value)
                                                ).length > 0
                                                    ? true
                                                    : false)} 
                                                onChange={(e)=>{ 
                                                    if (e.target.checked) {
                                                        setSelectedRuleCommunity([
                                                            ...selectedRuleCommunity,
                                                            {
                                                                value               : row.id,
                                                                label               : row.publication_name,
                                                            },
                                                        ]);
                                                    }else{
                                                        // remove from list
                                                        setSelectedRuleCommunity(
                                                            selectedRuleCommunity.filter((rule) => rule.value !== row.id),
                                                        );
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                    ))
                                    :
                                    <strong>No community papers available</strong>
                            }

                            </div>
                            </>
                        }

                    </Col>
                </Row>
            }


            <Form onSubmit={handleSubmit} encType="multipart/form-data" >
                <Row  >
                    <Col className="mt-2" xs={12}>

                    { ( stepStatus === 2 && uploadStatus === 0) ?
                        <>
                            <h4>Step 2: Upload the list of reviewers and authors</h4>
                            <Row className="mt-4">
                                <div className="mb-3 col-12 col-lg-6 "> 

                                    <h5 className="mt-3">Reviewer CSV</h5>
                                    <div className="mt-1">Please upload a single comma seperated CSV file.
                                        <ul>
                                            <li><strong>File format:</strong> CSV (Comma separated)</li>
                                            <li><strong>Max file size:</strong> 10 MB</li>
                                            <li><strong>File columns:</strong> Email</li>
                                        </ul>
                                    </div>

                                
                                    <section className="dropZoneContainer">
                                        <div {...getRootReviewerProps({ className: 'dropzone' })}>
                                            <input {...getInputReviewerProps()} />
                                            <p>Drag 'n' drop a file here, or click to select file</p>
                                            <em>(Only *.csv file will be accepted)</em>
                                        </div>
                                        <aside style={{display: 'flex',flexDirection: 'row',flexWrap: 'wrap',marginTop: 16}}>
                                            {thumbs}
                                        </aside>
                                    </section>                        
                                </div>

                                <div className="mb-3 col-12 col-lg-6 "> 

                                    <h5 className="mt-3">Author CSV</h5>
                                    <div className="mt-1">Please upload a single comma seperated CSV file.
                                        <ul>
                                            <li><strong>File format:</strong> CSV (Comma separated)</li>
                                            <li><strong>Max file size:</strong> 10 MB</li>
                                            <li><strong>File columns:</strong> Email</li>
                                        </ul>
                                    </div>

                                    
                                    <section className="dropZoneContainer">
                                        <div {...getRootAuthorProps({ className: 'dropzone' })}>
                                            <input {...getInputAuthorProps()} />
                                            <p>Drag 'n' drop a file here, or click to select file</p>
                                            <em>(Only *.csv file will be accepted)</em>
                                        </div>
                                        <aside style={{display: 'flex',flexDirection: 'row',flexWrap: 'wrap',marginTop: 16}}>
                                            {thumbsAuth}
                                        </aside>
                                    </section>                        
                                </div>
                            </Row>
                        </>
                        :
                        ( stepStatus === 2 && uploadStatus === 1) ?
                            <h4>Step 2: File already uploaded</h4>
                        :
                            ''
                    }
                        
                        {isLoadingConferences ?
                    <Alert variant="info" className="mt-4"><Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{marginRight:10}}
                    />Please wait while we get rules...</Alert>

                    :
                    <>
                    { (defaultRules  && stepStatus === 3) && 
                    <>
                        <h4>Step 3: Conflict rule selection TEST</h4>
                        <Accordion >
                            <Accordion.Item >
                                <Accordion.Header><h5 className="mb-0">Conflict rules</h5></Accordion.Header>
                                <Accordion.Body>
                                    <p>Below are the default set of rules:</p>                            
                                    {defaultRules.map((row, index) => (
                                            <Form.Group key={`key-${index}-${row.value}`} className="mb-3" controlId="formBasicCheckbox">
                                                <Form.Check 
                                                type="checkbox"
                                                disabled={row.readonly_field}
                                                checked={(selectedRules.filter(i =>
                                                    [row.value].includes(i.value)
                                                  ).length > 0 || row.readonly_field
                                                    ? true
                                                    : false)} 
                                                label={`${row.label}`} 
                                                onChange={(e)=>{

                                                    // add rule to the list
                                                    if (e.target.checked) {
                                                        setSelectedRules([
                                                            ...selectedRules,
                                                            {
                                                                value: row.value,
                                                                label: row.label,
                                                                relation_type_id: row.relation_type_id,
                                                                start_date_check: row.start_date_check,
                                                                end_date_check: row.end_date_check,
                                                                readonly_field: row.readonly_field,
                                                                operation_txt: row.operation_txt,
                                                                number_of_year: row.number_of_year
                                                            },
                                                        ]);
                                                    }else{
                                                        // remove from list
                                                        setSelectedRules(
                                                            selectedRules.filter((rule) => rule.value !== row.value),
                                                        );
                                                    }

                                                }}
                                            />
                                            </Form.Group>
                                        ))}

                                        {/** Pending employment rules */}
                                        <Form.Group className="mb-3">
                                            <Form.Check 
                                                type="checkbox"
                                                label={`Pending employment or graduate school application with an on-going discussion: 6 months from the <application date>`}
                                                checked={(selectedRulesPendingEmp.filter(i =>
                                                    [1].includes(1)
                                                  ).length > 0 
                                                    ? true
                                                    : false)} 
                                                onChange={(e)=>{
                                                    // add rule to the list
                                                    if (e.target.checked) {
                                                        setSelectedRulesPendingEmp([
                                                            ...selectedRulesPendingEmp,
                                                            {
                                                                value: 1,
                                                                label: 'Pending employment or graduat...',
                                                                relation_type_id: 8,
                                                                start_date_check: 0,
                                                                end_date_check: 1,
                                                                readonly_field: 0,
                                                                operation_txt: 'WITHIN_X_MONTHS',
                                                                number_of_year: 0,
                                                                number_of_month: 6
                                                            },
                                                        ]);
                                                    }else{
                                                        // remove from list
                                                        setSelectedRulesPendingEmp(
                                                            selectedRulesPendingEmp.filter((rule) => rule.value !== 1),
                                                        );
                                                    }
                                                }}
                                                />
                                        </Form.Group> 

                                        {/** Institution rules */}
                                        { ( defaultRulesInst.length > 0 ) && 
                                            defaultRulesInst.map((row, index) => ( 
                                                <Form.Group key={`key-${index}-${row.value}`} className="mb-3" controlId="formBasicCheckbox">
                                                    <Form.Check 
                                                        type="checkbox"
                                                        label={`${row.label}`} 
                                                        checked={(selectedRulesInst.filter(i =>
                                                            [row.value].includes(i.value)
                                                          ).length > 0 || row.readonly_field
                                                            ? true
                                                            : false)} 
                                                        onChange={(e)=>{ 
                                                            if (e.target.checked) {
                                                                setSelectedRulesInst([
                                                                    ...selectedRulesInst,
                                                                    {
                                                                        value               : row.value,
                                                                        label               : row.label,
                                                                        years               : row.years,
                                                                        type_of_position    : row.type_of_position
                                                                    },
                                                                ]);
                                                            }else{
                                                                // remove from list
                                                                setSelectedRulesInst(
                                                                    selectedRulesInst.filter((rule) => rule.value !== row.value),
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            ))
                                        }

                                
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                    }
                    </>
            }

                    </Col>
                </Row>     

                { (stepStatus === 4) &&
                    <Row >
                        <Col xs={12}>
                            <h4>Step 4: Generate conflict file</h4>
                            
                            <div className="mt-3 mb-3">
                                <strong>File uploaded successfully. Please click on "Generate conflict" button to generate the output file.</strong>
                            </div>

                        </Col>
                    </Row>
                }

                { (stepStatus === 5) &&
                    <Row >
                        <Col xs={12}>
                            <h4>Step 5: Download conflict file</h4>

                            { (Object.keys(invalidUserList).length > 0 && stepStatus === 5 && downloadLink !== '' ) && <>
                                <span className="text-danger">Below is the list of emails not found in the database.</span><br/>
                                    {
                                        Object.keys(invalidUserList).map((type,i) => {

                                            
                                                const emails_list = invalidUserList[type].map( (emails, index)  => { 

                                                    return ( 
                                                            <li key={index}>{ emails }</li>
                                                        )

                                                });
                                            

                                            return (
                                                <React.Fragment key={i} >
                                                    { (invalidUserList[type].length > 0) &&
                                                        <>
                                                            <span> { type === 'reviewer_invalid' ? 'Reviewer emails' : 'Author emails' }</span>
                                                            <ul>
                                                                { emails_list }
                                                            </ul>
                                                        </>
                                                    }
                                                </React.Fragment>
                                            )

                                        })
                                    }

                                </>
                            } 


                        </Col>
                    </Row>
                }
            
        
                <Card.Footer>
                         
            
                <Row> {/* show uploader and cancel buttons */}
                    <Col>   
                       
                        <div className="mt-3 d-flex justify-content-end">

                            {(stepStatus >=2) &&      
                                <Button variant="primary" className="animate-hover btn btn-sm btn-secondary" onClick={previousStep}>
                                    <FontAwesomeIcon icon={faArrowLeft} className="animate-left-3 me-3 ms-2" />
                                    Previous
                                    </Button>

                            }

                            { (stepStatus >= 2 && fileUploadProgress) ?
                                <Button variant="primary" disabled>
                                    <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                    Uploading. Please wait...
                                </Button>
                            :
                            <>
                                {(showImportNowBtn && stepStatus === 4 && downloadLink === '') &&                                   
                                    <Button type="button" className="animate-hover btn btn-sm btn-addnew" onClick={handleImportNow}  >
                                        <FontAwesomeIcon icon={faFileCsv} className="animate-left-3 me-3 ms-2" /> Generate conflict
                                    </Button>
                                }

                                {(stepStatus === 2 ) &&                                   
                                    <Button variant="primary" type="submit" className="animate-hover"><FontAwesomeIcon icon={faUpload} className="animate-left-3 me-3 ms-2" />{uploadButtonText}</Button>
                                }
                            </>
                                
                            }

                            {(downloadLink !== '' && stepStatus === 5 ) && 
                                <>
                                    <a href= {`${API_URL}/v1/relation/data/downloadCSV/?file=${downloadLink}`}  target="_blank"  rel="noreferrer">
                                        <Button><FontAwesomeIcon icon={faDownload} className="animate-left-3 me-3 ms-2" /> Download conflict CSV file </Button>
                                    </a>
                                </>
                            }

                            

                            { (  (uploadStatus === 0 && stepStatus !== 2 )  || ( (uploadStatus === 1 && stepStatus <= 3) ) )  &&
                                    <Button type="button" className="animate-hover" onClick={nextStep}  > <FontAwesomeIcon icon={faArrowRight} className="animate-left-3 me-3 ms-2" />
                                    Next
                                    </Button>
                            }

                            {(stepStatus === 4 ) &&
                                <Link to="" onClick={()=>{ window.location.reload()} } className="animate-hover  btn btn-sm btn-secondary"><FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" /> Cancel</Link>
                            }

                        </div>
                    </Col> .
                </Row>
               
                                           
                </Card.Footer>

            </Form> 

        </>
    );
}

async function myCustomFileGetter(event) {
    const files = [];
    const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

   // console.log("^^^^^^^^^^^^^");
   // console.log(fileList);
    for (var i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        
        const reader = new FileReader();

        reader.addEventListener("load", () => {
            //let formData = new FormData();

            Object.defineProperty(file, 'base64Data', {
                value: reader.result
            });

            
        },false);

        /*Object.defineProperty(file, 'myProp', {
            value: true
        });*/
        reader.readAsDataURL(file);

        files.push(file);
    }

    return files;
}

function getfileRejectionsError(fileRejectionsData, uploader_type)
{
    const fileRejectionItems = fileRejectionsData.map( ({ file, errors  }, index)  => { 
                      
        return (
          <li key={`mainkey_${index}`} > 
            {file.name} - {file.size} bytes asdasd { uploader_type }
            <ul>
                {errors.map(e => <li key={`childkey_${index}_${e.code}`} >{(e.code === "too-many-files") ? "Only single CSV allowed."  : e.message}</li>)}
            </ul>
          </li>
        ) 
    });

    return fileRejectionItems;
    
}
export default ConflictExport;