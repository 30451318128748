import { Col, Row } from '@themesberg/react-bootstrap';
import useFetch from "../../framework/hooks/useFetch";
import { useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { StatusInGrid } from "../../framework/utilities/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const ImportUserStatusList = () => {
    
    console.log("Inside ImportUserStatusList");

    //getting the user import file listing
    const API_URL = process.env.REACT_APP_API_URL;
    let obj = useFetch(API_URL + '/v1/user/data/import_csv_list', 'POST');
    const rowData = obj.data.data;
    const gridRef = useRef(null);
    const rowHeight = 54;

    
    const columns =[
        {headerName:"Filename",field:"Filename",sortable:true,filter:true,flex: 1},
        {headerName:"Uploaded at",field:"CreatedOn",sortable:true,filter:true,minWidth:200,maxWidth:210},
        {headerName:"Read rows",field:"ImportedRows",sortable:true,filter:true,minWidth:200,maxWidth:210},
        {headerName:"Started at",field:"ImportedStarteDate",sortable:true,filter:true,minWidth:200,maxWidth:210},        
        {headerName:"Ended at",field:"ImportedEndedDate",sortable:true,filter:true,minWidth:200,maxWidth:210},      
        {headerName:"Status",field:"Status",sortable:true,filter:true,flex: 1,cellRenderer: params => {
                return StatusInGrid({value:params.value, custom_status:[
                        {value:'0',label:'Not imported',className:'bg-danger' },
                        {value:'1',label:'Imported',className:'bg-success' },
                        {value:'2',label:'Set for import',className:'bg-info' },
                        {value:'3',label:'Running',className:'bg-warning' },
                    ] });                    
            } 
        }, 
    ];

    
    const heading = "Import status";

    return ( 
        <>        
            <h4>{heading}
                <Link to="/courses?sort=name" />
                <span style={{cursor:'pointer'}} onClick={()=>{ window.location.reload()}} >
                    <FontAwesomeIcon icon={faSyncAlt} className="animate-left-3 me-3 ms-2" />
                </span>
            </h4>
            <Row>
                <Col>
                <ul>
                    <li>An import cron runs every 15 mins to check if new files are set for import (Timings: 9:00, 9:15, 9:30, 9:45, 10::00 etc).</li>
                    <li>You can refresh this page to see status of the imports.</li>
                    <li>Importing 1000 rows takes about 15 mins on average. Incase you see any issues please contact us asap.</li>
                    <li>No invite mails are sent out to the user. If you want to trigger the invites please visit "Users - Send invites" module.</li>
                </ul>
                </Col></Row>
            <Row className="mt-3">
                <Col xs={12}>
                    <div className="users-page">
                        {obj.isError && <div className="alert alert-danger" role="alert">{obj.isError}</div>}
                        {obj.isPending && <div>Loading...</div>}
                        {obj.data &&
                            <>
                                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                                    <AgGridReact
                                        className="user-table align-items-center"
                                        ref={gridRef}
                                        rowData={rowData}
                                        rowHeight={rowHeight}
                                        columnDefs={columns}
                                        pagination={true}                                        
                                        rowSelection={'single'}
                                        >
                                    </AgGridReact>
                                </div >
                            </>
                        }
                    </div>

                </Col>
            </Row>
        </> 
    );
}
 
export default ImportUserStatusList;

