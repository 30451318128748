
import React, { useState, useEffect } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation, Link } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane,faCog, faSignOutAlt, faHome, faUsers,faUserCog,faUniversity, faKey,faClipboardList,faFileExport,faListAlt,faFileImport, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';

import { Routes } from "../../routes";
//import Logo from "../../assets/img/logo.png";
//import ReactHero from "../../assets/img/technologies/react-hero-logo.svg";
import ProfilePicture from "../../assets/img/team/default.png";

import Logout from "../../framework/utilities/Logout";
import LogoImage from "../../assets/img/brand/conflict-logo.png";

let sessionUser = JSON.parse(localStorage.getItem("profile"));


export default function Sidebar(props = {}) {
    const location = useLocation();
    const { pathname } = location;
    const [show, setShow] = useState(false);
    const [showMenu, setShowMenu] = useState(true);
    const showClass = show ? "show" : "";


    const onCollapse = () => setShow(!show);

    useEffect(()=>{
        if (!sessionUser){
            //let us logout the user
            localStorage.clear();
            window.location.href='/';
            console.log("No session user");
        }
    });  

    const CollapsableNavItem = (props) => {
        const { eventKey, title, icon, children = null } = props;
        const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

        return (
            <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
                <Accordion.Item eventKey={eventKey}>
                    <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
                        <span>
                            <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
                            <span className="sidebar-text">{title}</span>
                        </span>
                    </Accordion.Button>
                    <Accordion.Body className="multi-level">
                        <Nav className="flex-column">
                            {children}
                        </Nav>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    };

    const NavItem = (props) => {
        const { title, link, external, target, icon, image, badgeText, badgeBg = "danger", badgeColor = "white",onChildClick } = props;
        const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
        const navItemClassName = link === pathname ? "active" : "";
        const linkProps = external ? { href: link } : { as: Link, to: link };

        return (
            <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
                <Nav.Link {...linkProps} target={target} className={classNames} onClick={ onChildClick } >
                    <span>
                        {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
                        {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

                        <span className="sidebar-text">{title}</span>
                    </span>
                    {badgeText ? (
                        <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
                    ) : null}
                </Nav.Link>
            </Nav.Item>
        );
    };

    return (
        <>
            <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
                <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
                    {/*<Image src={ReactHero} className="navbar-brand-light" />*/}
                </Navbar.Brand>
                <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
                    <span className="navbar-toggler-icon" />
                </Navbar.Toggle>
            </Navbar>
            <div className={`sideMenuWrap ${showMenu === true ? 'showMenu' : ''} `}>
                <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
                    <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
                        <div className="sidebar-inner ">
                            <div>
                            <a href="/" className="nav-link pt-md-4 pb-md-1"  ><img className="conflictdb-logo" src={LogoImage} alt="ConflictDB" title="ConflictDB" /></a>
                            </div>
                            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-3 pe-4 navbar-dark">
                                <div className="d-flex align-items-center ps-3">
                                    <div className="user-avatar lg-avatar me-4">
                                        <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                                    </div>
                                    <div className="d-block pt-2">
                                        {sessionUser && <h6>Hi, { sessionUser.FName }</h6>}
                                        {/* <Button  variant="secondary" size="xs" className="text-dark" onClick={Logout} >
                                            <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign out
                                        </Button> */}
                                    </div>
                                </div>
                                <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
                                    <span className="navbar-toggler-icon" />
                                </Navbar.Toggle>
                            </div>

                            <Nav className="flex-column pt-3 pt-md-0">
                                <div className="menuicon" onClick={() => setShowMenu(!showMenu)}>
                                    <div className="line line1"></div>
                                    <div className="line line2"></div>
                                    <div className="line line3"></div>
                                </div>
                                
                                    {/* <Image src={Logo} style={{ width: '40px' }} className="sidebar-icon svg-icon" /> */}
                                    
                                    {/*<span style={{
                                        display: 'inline-block',
                                        top: '4px',
                                        fontWeight: 700,
                                        letterSpacing: '0.9px'
                                    }}>
                                        ConflictDB
                                </span>*/}
                                

                                <Dropdown.Divider className="my-4 border-white opacity-0 d-none d-md-block" />

                                {/* <NavItem title="Volt React" link={Routes.Presentation.path} image={ReactHero} /> */}
                                <NavItem title="Dashboard" link={Routes.DashboardOverview.path} icon={faHome} />
                                                                
                                <CollapsableNavItem eventKey="relation/" title="Relations" icon={faClipboardList}>
                                    <NavItem title="Relations" link={Routes.Relations.path} icon={faClipboardList} />
                                    <NavItem title="Import relations" link={Routes.Relations.importpath} icon={faFileImport} /> 
                                </CollapsableNavItem>

                                <NavItem title="Institutions" link={Routes.Institution.path} icon={faUniversity} />
                                
                                <CollapsableNavItem eventKey="users/" title="Users" icon={faUsers}>
                                    <NavItem title="List all users" link={Routes.Users.path} icon={faUsers} />
                                    <NavItem title="New registrations" link={Routes.userRegistration.path} icon={faUserPlus} />
                                    <NavItem title="Import users" link={Routes.Users.importpath} icon={faFileImport} />  
                                    <NavItem title="Send invites" link={Routes.userInviteSend.path} icon={faPaperPlane} />  
                                </CollapsableNavItem>
                                
                                {/*<NavItem title="Conferences" link={Routes.Conferences.path} icon={faHandshake}/>*/}
                                
                                <CollapsableNavItem eventKey="exports/" title="Exports" icon={faFileExport}>
                                    <NavItem title="Conflict export" link={Routes.ConflictExport.path} icon={faFileExport} />
                                </CollapsableNavItem>

                                

                                <CollapsableNavItem eventKey="settings/" title="Settings" icon={faCog}>
                                    
                                    <NavItem title="User Roles" link={Routes.UserRole.path} icon={faUserCog} />                                    
                                    <NavItem title="Relation Types"  link={Routes.RelationType.path} icon={faListAlt} />                                    
                                    <NavItem title="Reset password" link={Routes.PasswordReset.path} icon={faKey} />  
                                    {/*<NavItem title="Email templates" link={Routes.DashboardOverview.path} icon={faEnvelopeOpenText} /> */}
                                </CollapsableNavItem>

                                <Dropdown.Divider className="my-3 border-white" />

                                <NavItem title="Logout"  link="/#" icon={faSignOutAlt} onChildClick={Logout} />
                            </Nav>
                        </div>
                    </SimpleBar>
                </CSSTransition>
            </div>
        </>
    );
};
