import { Col, Row, Button } from '@themesberg/react-bootstrap';
import { Link,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../routes";
import useFetch from "../../framework/hooks/useFetch";
import { useRef,useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import ConfirmDeleteModal from '../../project/components/ConfirmDeleteModal';
import { StatusInGrid } from "../../framework/utilities/utilities";

const InstitutionList = () => {

    const history = useHistory();
    let [modalShow, setModalShow] = useState(false);
    let [delRecId, setDelRecId] = useState(0);
    let [delRecTitle, setDelRecTitle] = useState('');
    const [gridApi, setGridApi] = useState(null);
    //const [gridColumnApi, setGridColumnApi] = useState(null);

    const deleteBtn=(params)=>{        
        setDelRecId(params.value);
        setModalShow(true);
        setDelRecTitle(params.data.IName);
    }

    const heading = "Institution list";
    const API_URL = process.env.REACT_APP_API_URL;    
    let obj = useFetch(API_URL + '/v1/institution/data/list?Page_Limit=10000&Page=1', 'POST');
    const rowData = obj.data.data;

    
    const gridRef = useRef(null);

    
    const editBtn=(params)=>{
        //console.log(params);
        history.push(`${Routes.Institution.editpath.replace(':id',params.value)}`);
    }
    
    
    const columns =[
        {headerName:"Institution name",field:"IName",sortable:true,filter:true,flex: 1,resizable: true},
        {headerName:"Status",field:"Status",sortable:true,filter:false,flex: 2, minWidth:100,maxWidth:120,cellRenderer: params => {
                return StatusInGrid({value:params.value,custom_status: [ {value:'3',label: 'Unverified'}, {value:'4',label: 'some otehr'} ] });                    
            } 
        }, 
        {headerName:"",field:"GID",flex: 2,minWidth:50,maxWidth:50,
        cellRendererFramework:(params)=><div>   
            <span onClick={()=>editBtn(params)} title="Edit" className="grid-action-btns text-success link"><FontAwesomeIcon icon={faEdit} className="me-2" /></span>                     
        </div>},
        {headerName:"",field:"GID",flex: 2,minWidth:50,maxWidth:50,
        cellRendererFramework:(params)=><div>               
            <span onClick={()=> {  deleteBtn(params);}} title="Delete" className="grid-action-btns text-danger link"><FontAwesomeIcon icon={faTrashAlt} className="me-2" /></span>  
        </div>}
    ];

    const rowClassRules = {
        // apply red colour to all unverified rows
        'unverified-row': function(params) { return params.data.Status === 3; },    
    };


    const handleModelClose = () => {
        setModalShow(false);
         // calling the hook here
        // useForceUpdate();
    }

    const handleOnDeleteSubmitted = () => {
        
        fetch(API_URL + '/v1/institution/data/delete/'+ delRecId, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
        }).then(response => response.json())
        .then(jsondata => {           
            handleModelClose(false);      
            const selectedData = gridApi.getSelectedRows();
            //const res = gridApi.applyTransaction({ remove: selectedData });
            gridApi.applyTransaction({ remove: selectedData });
            //printGridResult(res);     
        }).catch((err) => {
            console.log("Login error: " + err);
        });

        
        
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        //setGridColumnApi(params.columnApi);
    };
    
    return ( 
            <>                
                <ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false);}} moduleName="institution" delRecTitle={delRecTitle} handleOnDeleteSubmitted={handleOnDeleteSubmitted} extraMessage="If there are any linked pending employment relations or institution affiliations they will also be deleted." />

                <ModuleBreadcrumb links={[{ label: heading }]}  />
                <h4>{heading}</h4>
                <Row >
                    <Col xs={12}>
                        <Button as={Link} variant="primary" className="animate-hover" to={Routes.Institution.addpath}>
                            <FontAwesomeIcon icon={faPlus} className="animate-left-3 me-3 ms-2" />
                            Add new institution
                        </Button> 

                    </Col>
                </Row>
                <Row className="mt-3">
                <Col xs={12}>
                    <div className="users-page">
                        {obj.isError && <div className="alert alert-danger" role="alert">{obj.isError}</div>}
                        {obj.isPending && <div>Loading...</div>}
                        {obj.data &&
                            <>
                                <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                                    <AgGridReact
                                        className="user-table align-items-center"
                                        ref={gridRef}
                                        rowData={rowData}
                                        columnDefs={columns}
                                        pagination={true} 
                                        rowClassRules={rowClassRules}
                                        onGridReady={onGridReady}
                                        rowSelection={'single'}
                                    >
                                    </AgGridReact>
                                </div >                
                            </>
                        }   
                    </div>
                </Col>
                </Row>
                
            </>
            );
};
 
export default InstitutionList;



