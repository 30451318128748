import { Col, Row, Button, Form, Accordion  } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFileImport, faPaperPlane, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../routes";
import { useState } from 'react';
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { useForm } from "react-hook-form";
import SearchGrid from "./SearchGrid";

const UserList = () => {

    const [searchList, setSearchList] = useState(false);
    const [searchButtonClicked, setSearchButtonClicked] = useState(false);
    
    const { register, handleSubmit, setValue} = useForm();

    const heading = "Users";

    
    const clearSearchFilters = () => {
        setValue('FName','');
        setValue('SName','');
        setValue('Email','');
    }

    const [searchFormData, setSearchFormData] = useState([{FName: "", SName: "", Email: ""}]);
   
    const onSearchSubmit = postedData => {
        
        /*if ((postedData.FName === '') && (postedData.SName === '') && (postedData.Email === '')){
            return false;
        }*/
        
       // console.log("inside onSearchSubmit", postedData);
        setSearchButtonClicked(true);
        let tmp = {};
        tmp["FName"] =  postedData.FName;
        tmp["SName"] =  postedData.SName;
        tmp["Email"] =  postedData.Email;
        setSearchFormData([tmp]);
       
        if (searchList){
            setSearchList(false);
        }else{
            setSearchList(true);
        }
       
       /* ReactDOM.render(

            <SearchGrid searchList={searchList} postedData={postedData} />,
            document.getElementById('searchResults')
        );*/

    }   

    return ( 
        <> 
            <ModuleBreadcrumb links={[{ label: heading }]}  />
            <h4>{heading}</h4>
            <Row >
                <Col xs={12}>
                    <Button as={Link} variant="primary" className="animate-hover" to={Routes.Users.addpath}>
                        <FontAwesomeIcon icon={faPlus} className="animate-left-3 me-3 ms-2" />
                        Add new user
                    </Button> 
                    <Button as={Link} className="animate-hover btn-info" to={Routes.Users.importpath}>
                        <FontAwesomeIcon icon={faFileImport} className="animate-left-3 me-3 ms-2" />
                        Import users
                    </Button>
                    <Button as={Link} className="animate-hover btn-addnew" to={Routes.userInviteSend.path}>
                        <FontAwesomeIcon icon={faPaperPlane} className="animate-left-3 me-3 ms-2" />
                        Send invites
                    </Button>              
                </Col>
            </Row>
            
            <Accordion  className="mt-4 mb-4">                        
                <Accordion.Item >
                    <Accordion.Header>Advanced search</Accordion.Header>
                    <Accordion.Body>
                        <Form className="mt-4" onSubmit={handleSubmit(onSearchSubmit)}>
                            <Row className="mt-3">
                                <Col className="col-12 col-md-4">
                                    <Form.Group id="FName">
                                        <Form.Label>First name</Form.Label>
                                        <Form.Control type="text" placeholder="" {...register("FName")} />
                                    </Form.Group>                                    
                                </Col>  
                                <Col className="col-12 col-md-4">
                                    <Form.Group id="SName">
                                        <Form.Label>Last name</Form.Label>
                                        <Form.Control type="text" placeholder="" {...register("SName")} />
                                    </Form.Group>                                    
                                </Col>  
                                <Col className="col-12 col-md-4">
                                    <Form.Group id="Email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="text" placeholder="" {...register("Email")} />
                                    </Form.Group>                                    
                                </Col>                   
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-6">
                                    <Button variant="primary" type="submit"  className="animate-hover" >
                                        <FontAwesomeIcon icon={faSearch} className="animate-left-3 me-3 ms-2" />
                                        Search</Button>
                                    <Button variant="secondary"  className="animate-hover" onClick={clearSearchFilters} >
                                        <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                                        Clear filters</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <SearchGrid searchList={searchList} searchFormData={searchFormData} searchButtonClicked={searchButtonClicked} />
        </> 
    );
}
 
export default UserList;

