import { useState, useEffect } from "react";
import { Col, Row, Form, Card, Button, Alert, Spinner } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import ImportRelationStatusList from './ImportRelationStatusList';

const RelationImport = () => {

    const API_URL = process.env.REACT_APP_API_URL;
    const heading = "Relation import";
    const [files, setFiles] = useState([]);
    const [fileUploadProgress, setFileUploadProgress] = useState(false);
    const [errMessage,setErrorMessage] = useState('');
    const [successMessage,setSuccessMessage] = useState('');
    const [fileRejectionItems,setFileRejectionItems] = useState('');
    const [showImportNowBtn,setShowImportNowBtn] = useState(false);
    const [fileToken,setFileToken] = useState('');
    const [refreshList, setRefreshList] = useState(true);

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.csv',  
        maxFiles: 1,
        maxSize: 10000000,//10MB
        multiple: false,        
        onDrop: (acceptedFiles,fileRejections) => {
            setFiles(
                acceptedFiles.map(file => Object.assign(
                    file, { preview: URL.createObjectURL(file), base64Data: file.base64Data }
                ))
            );
            //console.log('fileRejections',fileRejections);
                    
            const fileRejectionItems = fileRejections.map( ({ file, errors  }, index)  => { 
                
                
                return (
                  <li key={`mainkey_${index}`} > 
                    {file.name} - {file.size} bytes
                    <ul>
                        {errors.map(e => <li key={`childkey_${index}_${e.code}`} >{(e.code === "too-many-files") ? "Only single CSV allowed."  : e.message}</li>)}
                    </ul>
                  </li>
                ) 
            });
               
            if (fileRejectionItems.length > 0)
                setFileRejectionItems(fileRejectionItems);
            else
                setFileRejectionItems('');
        },
        getFilesFromEvent: event => myCustomFileGetter(event)
    });

    

    const thumbs = files.map(file => (
        <div style={{display: 'inline-flex',borderRadius: 2,border: '1px solid #eaeaea',marginBottom: 8,marginRight: 8,width: "100%",height: 100,padding: 4,boxSizing: 'border-box'}} key={file.name}>
            <div style={{display: 'flex',minWidth: 0,overflow: 'hidden', height: '60px'}}>
                {
                    (file.type.includes('image')) ? 
                    <img
                        src={file.preview}
                        style={{display: 'block',width: 'auto',height: '100%'}}
                        alt=""
                    />
                    :
                    <>
                        <FontAwesomeIcon icon={faFileCsv} style={{width:"auto",height:"100%"}} />
                        <strong style={{ paddingLeft: "10px", display: "inline-flex" , justifyContent: "center" }}>
                            <span style={{ alignItems: "center" ,display: "inline-flex"  }}>
                                {file.name}
                            </span>
                        </strong>        
                    </>
                }
                
            </div>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);


    const handleImportNow = () =>{
        setRefreshList(false);
        //console.log("comes here handleImportNow" ,fileToken);
        if (fileToken !== ''){
            var formData = new FormData();
            formData.append("token", fileToken);    
            fetch(API_URL + '/v1/relation/data/set_for_import', { 
                method: 'POST', 
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token"),                    
                }, 
                body: formData 
            })
            .then(response => response.json())
            .then(jsondata => {  
                setRefreshList(true);             
                if (jsondata.error === 'SET_FOR_IMPORT_FAILED'){
                    setErrorMessage(jsondata.message);   
                }else if (jsondata.message === 'SET_FOR_IMPORT_SUCCESS'){                    
                    setSuccessMessage(() => {
                        return (<><p>The file is set for import. You may close this module and logout from admin.</p>
                            <p>If the records are not imported in next one hour it could be due to cron failure. In that case please contact us.</p></>
                        )
                    });
                    setShowImportNowBtn(false);
                }               
            }).catch((err) => {                
                console.log("Login error: " + err);
                setSuccessMessage("File could not be set for import.")
            });
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log("*************");
        //console.log(files);
        if (files.length === 0){
            setErrorMessage("Please upload the CSV file.");   
            setSuccessMessage("");         
        }else{
            setErrorMessage(""); 
            setSuccessMessage("");           
            setFileUploadProgress(true);

            if (files.length > 0){
                var formData = new FormData();
                //formData.append("FileData", files.base64Data);
                formData.append("FileData", files[0]);            
                //formData.append("FileBase64", files[0].base64Data);
    
                fetch(API_URL + '/v1/relation/data/upload_csv', { 
                    method: 'POST', 
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem("token"),                    
                    }, 
                    body: formData 
                })
                .then(response => response.json())
                .then(jsondata => {
                    setFiles([]);
                    if (jsondata.message === 'UPLOAD_FAILED'){
                        setErrorMessage(jsondata.errMessage);
                    }else{
                        //console.log("successful");
                        setSuccessMessage("File uploaded successfully. Please click on button below to set for import.");  
                        setFileToken(jsondata.file_path_token);
                        setShowImportNowBtn(true);
                    }
                    setFileUploadProgress(false);
                }).catch((err) => {
                    setFiles([]);
                    console.log("Login error: " + err);
                    setFileUploadProgress(false);
                    setErrorMessage("File could not be saved. Please try after some time.");
                });
            }   
        }
            

    }

    return (  
        <>
            <ModuleBreadcrumb links={[{ label:"Users", linkto: Routes.Relations.path } , { label: heading } ]}  />
            <h4>{heading}</h4>
            {fileUploadProgress && !errMessage && <Alert variant="info">File upload in progress...</Alert>}
            {errMessage && <Alert variant="danger">{errMessage}</Alert>}
            {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}  
            {fileRejectionItems && 
                <div>
                    <strong className="text-danger">The following files could not be uploaded.</strong>
                    <ul className="text-danger">{fileRejectionItems}</ul>  
                </div>
            }
             
            
            <Form className="mt-4" onSubmit={handleSubmit} encType="multipart/form-data" >
                {(!showImportNowBtn) &&
                <Row>
                    <Col xs={12}>
                        <div className="mt-4">Please upload a single comma seperated CSV file.
                            <ul>
                                <li><strong>File format:</strong> CSV (Comma separated)</li>
                                <li><strong>Max file size:</strong> 5MB</li>
                                <li><strong>Max rows in CSV:</strong> 1000</li>
                                <li><strong>File columns:</strong> PaperYear,PaperName,DBLP_PAPER_URL,AUTHOR_DBLP_ID_NAME,COAUTHORS_DBLP_ID_NAME</li>
                            </ul>
                        </div>
                        <div className="mb-3 col-sm-10"> 
                            <section className="dropZoneContainer">
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop a file here, or click to select file</p>
                                    <em>(Only *.csv file will be accepted)</em>
                                </div>
                                <aside style={{display: 'flex',flexDirection: 'row',flexWrap: 'wrap',marginTop: 16}}>
                                    {thumbs}
                                </aside>
                            </section>                        
                        </div>
                    </Col>
                </Row> 
                }   
                <Card.Footer>
                    <Row>   
                        <Col>
                            <div className="mt-3 d-flex justify-content-end">
                            {fileUploadProgress ?
                                <Button variant="primary" disabled>
                                    <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                    Uploading. Please wait...
                                </Button>
                            :
                            <>
                                { (showImportNowBtn) ?                                   
                                    <Button type="button" className="animate-hover btn btn-sm btn-addnew" onClick={handleImportNow}  >
                                        <FontAwesomeIcon icon={faFileCsv} className="animate-left-3 me-3 ms-2" /> Import now
                                    </Button>
                                    :
                                    <Button variant="primary" type="submit" className="animate-hover"  ><FontAwesomeIcon icon={faUpload} className="animate-left-3 me-3 ms-2" />Upload</Button>
                                }
                            </>
                                
                            }
                                
                            <Link to={Routes.Relations.path} className="animate-hover  btn btn-sm btn-secondary"><FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" /> Cancel</Link>
                            </div>
                        </Col>                    
                    </Row>
                </Card.Footer>
            </Form>  
            <hr/>            
            {refreshList && <ImportRelationStatusList />}          
        </>
    );
}
 
async function myCustomFileGetter(event) {
    const files = [];
    const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

   // console.log("^^^^^^^^^^^^^");
   // console.log(fileList);
    for (var i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        
        const reader = new FileReader();

        reader.addEventListener("load", () => {
            //let formData = new FormData();

            Object.defineProperty(file, 'base64Data', {
                value: reader.result
            });

            
        },false);

        /*Object.defineProperty(file, 'myProp', {
            value: true
        });*/
        reader.readAsDataURL(file);

        files.push(file);
    }

    return files;
}


export default RelationImport;